import React from 'react';
import Distance from './Distance';
import Confetti from 'react-dom-confetti';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chat:[],
      chatQueue:[this.hello(), this.ready(), this.startButton()],
      runConfetti: false,
    };
  }

  hello = () => (<p key="hello">Hello traveler!</p>);

  ready = () => (<p key="ready">Are you ready for your journey?</p>);

  startButton = () => (<p key="startButton"><button onClick={this.verify} id="startButton">Click to start the adventure!</button></p>);

  verify = () => {
    document.getElementById("startButton").disabled = true;
    this.addDialog([<p key="verify">We will start with verifying your geolocalization device...</p>]);
    this.checkGeo();
  }

  runConfetti = () => {
    this.setState({
      runConfetti: true,
    });
  }

  checkGeo = () => {
    // if(true) {
    if (navigator.geolocation) {
      this.addDialog([<p key="acquiring">Acquiring your current position...</p>]);
      navigator.geolocation.getCurrentPosition(this.geoSuccess);
      // this.geoSuccess({coords:{latitude: 0, longitude: 0}})
    } else {
      this.addDialog([<p key="checkGeo">Geolocation is not supported for this device.</p>]);
    }
  }

  geoSuccess = (position) => {
    this.addDialog([<p key="geoSuccess">Your current location is {position.coords.latitude.toFixed(5)}, {position.coords.longitude.toFixed(5)}.</p>,
      <p key="letsMeet">Let's meet at the entrance to the Błękitek building!</p>,
      <p key="blekitek"><img src="blekitek.jpg" alt="Błękitek" onLoad={this.scrollToEnd} /></p>,
      <p key="imComing"><button onClick={this.startNav} id="imComingButton">OK, here I come!</button></p>]);
  }

  startNav = () => {
    document.getElementById("imComingButton").disabled = true;
    this.addDialog([<Distance targetLat={50.05726} targetLon={19.96117} key="distPar" reachedLocation={this.reachedLocation}/>]);
  }

  reachedLocation = () => {
    this.addDialog([<p key="reachDest">Good job!! You have reached the destination!</p>,
      <p key="question">So, could you tell me which company has an office on the 18-th floor?</p>,
      <p key="enterance">Information should be available somewhere around the entrance...</p>,
      <p key="answer"><input type="text" id="answerInput"/><button onClick={this.checkAnswer} id="submitAnswer">Submit answer</button></p>]);
  }

  checkAnswer = () => {
    const answerInput = document.getElementById("answerInput");
    const submitAnswer = document.getElementById("submitAnswer");
    if(answerInput.value.toLowerCase().startsWith("metropolitan")) {
      submitAnswer.disabled = true;
      this.finalSuccess();
    } else {
      submitAnswer.innerText = "Try again";
      answerInput.value = "";
    this.addDialog([<p key="wrongAnswer">Unfortunately that is not correct. Please try again!</p>]);
    }
  }

  finalSuccess = () => {
    this.runConfetti();
    this.addDialog([<p key="correctAnswer">Well done! That is a correct answer!</p>,
      <p key="reward">Take this sweet cat image as a reward :)</p>,
      <p key="sweetCat"><img src="sweetCat.jpg" alt="Sweet cat" onLoad={this.scrollToEnd} /></p>]);
  }


  addDialog = (elements) => {
    this.setState({
      chatQueue: [
        ...this.state.chatQueue, 
        ...elements,
      ]
    });
  }

  popQueue = () => {
    if (this.state.chatQueue.length > 0) {
      this.setState({ 
        chat: [
          ...this.state.chat, 
          ...this.state.chatQueue.slice(0, 1),
        ],
        chatQueue: [...this.state.chatQueue.slice(1)],
      });
      this.scrollToEnd();
    }
  }

  scrollToEnd = () => {
    document.getElementById("end").scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  componentDidMount = () => {
    setInterval(this.popQueue, 500);
  }

  render = () => {
    return (
    <div className="App">
      <header className="App-header">
        {this.state.chat}
        <Confetti active={this.state.runConfetti} />
        <div id="end"> </div>
      </header>
    </div>
  )};
}

export default App;

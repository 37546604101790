import React from 'react';
import Map from './Map'

class Distance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          currLat: 0.0,
          currLon: 0.0,
          watchPositionId: undefined,
          distance: 0.0,
        };
    }

    componentDidMount() {
        const watchPositionId = navigator.geolocation.watchPosition(this.updatePos);
        this.setState({
            watchPositionId    
          });
        // this.updatePos({coords:{latitude: 0, longitude: 0}});
    }

    updatePos = (position) => {
        const distance = 1000 * this.calculateDistance
            (this.props.targetLat, this.props.targetLon, position.coords.latitude, position.coords.longitude);
        if (distance < 50) {
            navigator.geolocation.clearWatch(this.state.watchPositionId);
            this.props.reachedLocation();
        } else {
            this.setState({
            currLat: position.coords.latitude,
            currLon: position.coords.longitude,
            distance    
            });
        }
      }

    calculateDistance = (lat1, lon1, lat2, lon2) => {
        // return 0;
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * Math.PI / 180;  // deg2rad below
        var dLon = (lon2 - lon1) * Math.PI / 180;
        var a = 
           0.5 - Math.cos(dLat)/2 + 
           Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
           (1 - Math.cos(dLon))/2;
      
        return R * 2 * Math.asin(Math.sqrt(a));
    }

    render = () => {
        return(
            <>
                <Map currLat={this.state.currLat} currLon={this.state.currLon} destLat={this.props.targetLat} destLon={this.props.targetLon} />
                <p>You will reach the meeting point in {this.state.distance.toFixed(0)} meters.</p>
            </>
        );
    }
}

export default Distance;
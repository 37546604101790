import React, { Component } from 'react'
import { Map, TileLayer, Marker, CircleMarker} from 'react-leaflet'

export default class SimpleMap extends Component {
  state = {
    lat: 50.0571759, 
    lng: 19.9588552,
    zoom: 15,
  }

  render() {
    const destPosition = [this.props.destLat, this.props.destLon];
    const currPosition = [this.props.currLat, this.props.currLon];
    return (
      <Map center={destPosition} zoom={this.state.zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={destPosition}>
        </Marker>
        <CircleMarker center={currPosition} color="#ff3333" radius="5">
        </CircleMarker>
      </Map>
    )
  }
}